.tabs {
    &-nav {
        display: flex;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 24px;

        @media (min-width: $viewportS) {
            font-size: 14px;
        }
    }

    &-full {
        .tab-btn {
            width: 25%;
        }
    }
}

.tab {
    &-btn {
        border: 1px solid $borderColor;
        padding: 8px 16px;
        text-align: center;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $darkblueColor;

        & + .tab-btn {
            border-left: 0;
        }

        &:hover {
            background-color: $blueLightColor;
            cursor: pointer;
        }
        
        &.is-active {
            border-color: $blueColor;
            color: #fff;
            background-color: $blueColor;
        }

        &:first-child {
            border-top-left-radius: $borderRadiusSmall;
            border-bottom-left-radius: $borderRadiusSmall;
        }

        &:last-child {
            border-top-right-radius: $borderRadiusSmall;
            border-bottom-right-radius: $borderRadiusSmall;
        }
    }

    &-panel {
        display: none;

        &.is-active {
            display: block;
        }
    }
}