@import "variables";

@font-face {
  font-family: 'icomoon';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?bdne2x') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?bdne2x') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?bdne2x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icon-square {
  &:before {
    content: unicode($icon-square);     
  }
}
.icon-triangle {
  &:before {
    content: unicode($icon-triangle);     
  }
}
.icon-cart {
  &:before {
    content: unicode($icon-cart);     
  }
}
.icon-billboard {
  &:before {
    content: unicode($icon-billboard);     
  }
}
.icon-business {
  &:before {
    content: unicode($icon-business);     
  }
}
.icon-calculator {
  &:before {
    content: unicode($icon-calculator);     
  }
}
.icon-control {
  &:before {
    content: unicode($icon-control);     
  }
}
.icon-eye {
  &:before {
    content: unicode($icon-eye);     
  }
}
.icon-hand_mobile {
  &:before {
    content: unicode($icon-hand_mobile);     
  }
}
.icon-idea {
  &:before {
    content: unicode($icon-idea);     
  }
}
.icon-monitor {
  &:before {
    content: unicode($icon-monitor);     
  }
}
.icon-phone {
  &:before {
    content: unicode($icon-phone);     
  }
}
.icon-rocket {
  &:before {
    content: unicode($icon-rocket);     
  }
}