/* ==========================================================================
 * Normalize.scss settings
 * ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7 */
/* Base
 * ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *  user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units. */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin. */
body {
  margin: 0; }

/* HTML5 display definitions
 * ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11. */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera. */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices. */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22. */
[hidden],
template {
  display: none; }

/* Links
 * ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10. */
a {
  background: transparent; }

/**
 * Improve readability when focused and also mouse hovered in all browsers. */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
 * ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome. */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome. */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome. */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9. */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers. */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 * ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7. */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11. */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
 * ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari. */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers. */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers. */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome. */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
 * ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set. */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers. */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11. */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox. */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6. */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements. */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+. */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet. */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6. */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`. */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *  (include `-moz` to future-proof). */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance). */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding. */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7. */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11. */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X. */
optgroup {
  font-weight: bold; }

/* Tables
 * ========================================================================== */
/**
 * Remove most spacing between table cells. */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.btn {
  background-color: #fd4da6;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-decoration: none;
  padding: 0 32px;
  border-radius: 5px;
  transition: background-color 0.3s ease-out; }
  .btn:hover {
    background-color: #e0398e; }
  .btn:focus {
    outline: 0; }

* {
  box-sizing: border-box; }

img {
  max-width: 100%; }

.bg-light {
  background: #f6f9fc; }

.mt-24 {
  margin-top: 24px; }

.mt-32 {
  margin-top: 32px; }

.mt-104 {
  margin-top: 104px; }
  @media (max-width: 960px) {
    .mt-104 {
      margin-top: 50px; } }

.mt-136 {
  margin-top: 136px; }
  @media (max-width: 960px) {
    .mt-136 {
      margin-top: 80px; } }

@media (max-width: 960px) {
  .mt-s-16 {
    margin-top: 16px; } }

@media (max-width: 960px) {
  .mt-s-32 {
    margin-top: 32px; } }

@media (max-width: 640px) {
  .hidden-s {
    display: none !important; } }

@media (max-width: 960px) {
  .hidden-m {
    display: none !important; } }

@media (max-width: 960px) {
  .order-m-1 {
    order: 1; } }

[class*="flex-"] {
  display: flex;
  flex-wrap: wrap; }

.flex-x-between {
  justify-content: space-between; }

.flex-y-center {
  align-items: center; }

.flex-nowrap {
  flex-wrap: nowrap; }

input[type="text"] {
  background-color: #fff;
  border-radius: 5px;
  color: #27346e;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border: 0;
  transition: box-shadow 0.3s ease-out; }
  input[type="text"]::-webkit-input-placeholder {
    color: #ccc; }
  input[type="text"]::-moz-placeholder {
    color: #ccc; }
  input[type="text"]:-ms-input-placeholder {
    color: #ccc; }
  input[type="text"]:-moz-placeholder {
    color: #ccc; }
  input[type="text"]:focus {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
    outline: 0; }

.form {
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #377dff;
  padding: 32px; }
  @media (min-width: 960px) {
    .form {
      padding: 40px; } }
  .form .form-legend {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
    color: #fff; }
  .form .form-row:not(:first-child) {
    margin-top: 16px; }
  .form .form-row-btn {
    margin-top: 24px; }
  .form-disabled {
    position: relative;
    user-select: none; }
    .form-disabled:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      cursor: not-allowed;
      bottom: 0;
      z-index: 1; }

.container {
  max-width: 1184px;
  margin: 0 auto;
  padding: 0 16px; }
  @media (min-width: 960px) {
    .container {
      padding: 0 32px; } }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px; }
  @media (min-width: 960px) {
    .grid {
      margin-left: -32px; } }
  .grid > * {
    padding-left: 16px;
    width: 100%; }
    @media (min-width: 960px) {
      .grid > * {
        padding-left: 32px; } }
  .grid-center {
    margin-left: auto;
    margin-right: auto; }

.w-1-12 {
  width: 8.33333%; }

.w-1-6 {
  width: 16.66667%; }

.w-1-4 {
  width: 25%; }

.w-1-3 {
  width: 33.33333%; }

.w-1-2 {
  width: 50%; }

.w-2-3 {
  width: 66.66667%; }

.w-3-4 {
  width: 75%; }

.w-5-6 {
  width: 83.33333%; }

.w-5-12 {
  width: 41.66667%; }

.w-7-12 {
  width: 58.33333%; }

.w-1-1 {
  width: 100%; }

@media (min-width: 640px) {
  .w-s-1-12 {
    width: 8.33333%; }
  .w-s-1-6 {
    width: 16.66667%; }
  .w-s-1-4 {
    width: 25%; }
  .w-s-1-3 {
    width: 33.33333%; }
  .w-s-1-2 {
    width: 50%; }
  .w-s-2-3 {
    width: 66.66667%; }
  .w-s-3-4 {
    width: 75%; }
  .w-s-5-6 {
    width: 83.33333%; }
  .w-s-5-12 {
    width: 41.66667%; }
  .w-s-7-12 {
    width: 58.33333%; }
  .w-s-1-1 {
    width: 100%; } }

@media (min-width: 640px) {
  .grid-w-s-1-12 > * {
    width: 8.33333%; }
  .grid-w-s-1-6 > * {
    width: 16.66667%; }
  .grid-w-s-1-4 > * {
    width: 25%; }
  .grid-w-s-1-3 > * {
    width: 33.33333%; }
  .grid-w-s-1-2 > * {
    width: 50%; }
  .grid-w-s-2-3 > * {
    width: 66.66667%; }
  .grid-w-s-3-4 > * {
    width: 75%; }
  .grid-w-s-5-6 > * {
    width: 83.33333%; }
  .grid-w-s-5-12 > * {
    width: 41.66667%; }
  .grid-w-s-7-12 > * {
    width: 58.33333%; }
  .grid-w-s-1-1 > * {
    width: 100%; } }

@media (min-width: 960px) {
  .w-m-1-12 {
    width: 8.33333%; }
  .w-m-1-6 {
    width: 16.66667%; }
  .w-m-1-4 {
    width: 25%; }
  .w-m-1-3 {
    width: 33.33333%; }
  .w-m-1-2 {
    width: 50%; }
  .w-m-2-3 {
    width: 66.66667%; }
  .w-m-3-4 {
    width: 75%; }
  .w-m-5-6 {
    width: 83.33333%; }
  .w-m-5-12 {
    width: 41.66667%; }
  .w-m-7-12 {
    width: 58.33333%; }
  .w-m-1-1 {
    width: 100%; } }

@media (min-width: 960px) {
  .grid-w-m-1-12 > * {
    width: 8.33333%; }
  .grid-w-m-1-6 > * {
    width: 16.66667%; }
  .grid-w-m-1-4 > * {
    width: 25%; }
  .grid-w-m-1-3 > * {
    width: 33.33333%; }
  .grid-w-m-1-2 > * {
    width: 50%; }
  .grid-w-m-2-3 > * {
    width: 66.66667%; }
  .grid-w-m-3-4 > * {
    width: 75%; }
  .grid-w-m-5-6 > * {
    width: 83.33333%; }
  .grid-w-m-5-12 > * {
    width: 41.66667%; }
  .grid-w-m-7-12 > * {
    width: 58.33333%; }
  .grid-w-m-1-1 > * {
    width: 100%; } }

@media (min-width: 1280px) {
  .w-l-1-12 {
    width: 8.33333%; }
  .w-l-1-6 {
    width: 16.66667%; }
  .w-l-1-4 {
    width: 25%; }
  .w-l-1-3 {
    width: 33.33333%; }
  .w-l-1-2 {
    width: 50%; }
  .w-l-2-3 {
    width: 66.66667%; }
  .w-l-3-4 {
    width: 75%; }
  .w-l-5-6 {
    width: 83.33333%; }
  .w-l-5-12 {
    width: 41.66667%; }
  .w-l-7-12 {
    width: 58.33333%; }
  .w-l-1-1 {
    width: 100%; } }

@media (min-width: 1280px) {
  .grid-w-l-1-12 > * {
    width: 8.33333%; }
  .grid-w-l-1-6 > * {
    width: 16.66667%; }
  .grid-w-l-1-4 > * {
    width: 25%; }
  .grid-w-l-1-3 > * {
    width: 33.33333%; }
  .grid-w-l-1-2 > * {
    width: 50%; }
  .grid-w-l-2-3 > * {
    width: 66.66667%; }
  .grid-w-l-3-4 > * {
    width: 75%; }
  .grid-w-l-5-6 > * {
    width: 83.33333%; }
  .grid-w-l-5-12 > * {
    width: 41.66667%; }
  .grid-w-l-7-12 > * {
    width: 58.33333%; }
  .grid-w-l-1-1 > * {
    width: 100%; } }

[class*=push-] {
  position: relative; }

@media (min-width: 960px) {
  .push-m-1-12 {
    left: 8.333333%; } }

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 24px; }
  table:last-child {
    margin-bottom: 0; }
  @media (min-width: 640px) {
    table {
      font-size: 14px; } }

th, td {
  height: 48px;
  padding: 8px;
  border-right: 1px solid #EAF0F6;
  border-bottom: 1px solid #EAF0F6;
  word-wrap: break-word; }
  @media (min-width: 640px) {
    th, td {
      padding: 8px 16px; } }
  tr th:first-child, tr td:first-child {
    border-left: 1px solid #EAF0F6; }

th {
  background-color: #f6f9fc;
  vertical-align: top;
  font-weight: normal;
  text-align: left; }
  thead tr:first-child th {
    border-top: 1px solid #EAF0F6; }
  tr:first-child th:first-child {
    border-top-left-radius: 5px; }
  tr:first-child th:last-child {
    border-top-right-radius: 5px; }

td {
  vertical-align: middle; }
  tr:last-child td:first-child {
    border-bottom-left-radius: 5px; }
  tr:last-child td:last-child {
    border-bottom-right-radius: 5px; }

.tabs-nav {
  display: flex;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 24px; }
  @media (min-width: 640px) {
    .tabs-nav {
      font-size: 14px; } }

.tabs-full .tab-btn {
  width: 25%; }

.tab-btn {
  border: 1px solid #EAF0F6;
  padding: 8px 16px;
  text-align: center;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #27346e; }
  .tab-btn + .tab-btn {
    border-left: 0; }
  .tab-btn:hover {
    background-color: #f6f9fc;
    cursor: pointer; }
  .tab-btn.is-active {
    border-color: #377dff;
    color: #fff;
    background-color: #377dff; }
  .tab-btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .tab-btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.tab-panel {
  display: none; }
  .tab-panel.is-active {
    display: block; }

@font-face {
  font-family: 'Avenir Next', sans-serif;
  src: local("Avenir Next"), local("AvenirNext-Regular"), url("AvenirNext-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Avenir Next', sans-serif;
  src: local("Avenir Next"), local("AvenirNext-Bold"), url("AvenirNext-Bold.woff") format("woff");
  font-weight: bold; }

body {
  font-family: 'Avenir Next', sans-serif;
  line-height: 24px;
  font-size: 15px;
  color: #27346e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }
  @media (min-width: 640px) {
    body {
      font-size: 16px; } }

a {
  color: #377dff; }

p {
  margin: 0 0 16px; }

* + ul, * + p {
  margin-top: 16px; }

ul {
  list-style-type: square;
  padding-left: 20px; }
  ul li:not(:first-child) {
    margin-top: 16px; }

.text-right {
  text-align: right; }
  @media (min-width: 640px) {
    .text-right-s {
      text-align: right; } }

.text-center {
  text-align: center; }
  @media (min-width: 640px) {
    .text-center-s {
      text-align: center; } }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.ttf?bdne2x") format("truetype"), url("../fonts/icomoon/icomoon.woff?bdne2x") format("woff"), url("../fonts/icomoon/icomoon.svg?bdne2x#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-square:before {
  content: "\e900"; }

.icon-triangle:before {
  content: "\e901"; }

.icon-cart:before {
  content: "\e902"; }

.icon-billboard:before {
  content: "\e903"; }

.icon-business:before {
  content: "\e904"; }

.icon-calculator:before {
  content: "\e905"; }

.icon-control:before {
  content: "\e906"; }

.icon-eye:before {
  content: "\e907"; }

.icon-hand_mobile:before {
  content: "\e908"; }

.icon-idea:before {
  content: "\e909"; }

.icon-monitor:before {
  content: "\e90a"; }

.icon-phone:before {
  content: "\e90b"; }

.icon-rocket:before {
  content: "\e90c"; }

.header a, .footer a {
  color: #fff;
  text-decoration: none; }

.header .logo, .footer .logo {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px; }

.header .email, .footer .email {
  margin-left: 40px; }
  .header .email:hover, .footer .email:hover {
    text-decoration: underline; }

.header {
  margin-bottom: 54px; }

.footer {
  background-color: #27346e;
  color: #fff;
  padding: 24px 0; }

.hero {
  background: #377dff url(../images/pattern.png);
  color: #fff;
  padding: 40px 0 60px; }
  @media (min-width: 960px) {
    .hero {
      padding-bottom: 80px; } }
  .hero-title {
    font-size: 22px;
    line-height: 32px;
    font-weight: bold; }
    @media (min-width: 960px) {
      .hero-title {
        line-height: 40px;
        font-size: 32px; } }
  .hero-text {
    margin: 24px 0;
    font-size: 18px;
    line-height: 32px; }
    @media (min-width: 960px) {
      .hero-text {
        font-size: 24px; } }
  .hero-img {
    background: #fff;
    padding: 8px;
    border-radius: 15px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
    margin-top: 20px; }
    .hero-img img {
      display: block;
      margin: 0 auto; }
    @media (min-width: 960px) {
      .hero-img {
        margin-top: 0; } }

.service:not(:first-child) {
  margin-top: 56px; }

.arrows {
  margin-right: -10px;
  display: none; }
  @media (min-width: 1280px) {
    .arrows {
      display: block; } }

.question {
  background-color: #f6f9fc;
  padding: 16px;
  text-align: center;
  color: #686F8E;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #EAF0F6;
  border-radius: 12px;
  box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.05);
  flex: 1;
  margin: 5px;
  min-width: 150px; }
  @media (min-width: 960px) {
    .question:not(:first-child) {
      margin-top: 16px; } }
  .question-list {
    display: flex;
    flex-wrap: wrap; }

section {
  position: relative; }
  section .grid {
    align-items: center; }
  section table {
    table-layout: fixed; }

.section-block {
  padding: 60px 0;
  position: relative;
  overflow: hidden; }
  @media (min-width: 960px) {
    .section-block {
      padding: 104px 0; } }

.section .container {
  position: relative; }

.section-icon {
  margin-bottom: 24px;
  display: block;
  font-size: 40px; }

.section-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px; }
  @media (min-width: 640px) {
    .section-title {
      line-height: 32px;
      font-size: 26px; } }

.section-text {
  margin-bottom: 24px; }

.panel-blue, .panel-darkblue {
  position: relative; }
  .panel-blue:before, .panel-darkblue:before {
    left: 0;
    top: 0;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.2); }

.panel-blue:before {
  background-color: #377dff; }

.panel-darkblue .panel-content {
  padding: 0;
  border: 0;
  background-color: #27346e; }

.panel-darkblue img {
  border-radius: 15px;
  display: block;
  opacity: 0.75; }

.panel-darkblue:before {
  background-color: #27346e; }

.panel-left-top:before {
  transform: translateX(-10px) translateY(-10px); }
  @media (min-width: 640px) {
    .panel-left-top:before {
      transform: translateX(-20px) translateY(-20px); } }

.panel-right-bottom:before {
  transform: translateX(10px) translateY(10px); }
  @media (min-width: 640px) {
    .panel-right-bottom:before {
      transform: translateX(20px) translateY(20px); } }

.panel-content {
  position: relative;
  background-color: #fff;
  padding: 16px;
  border-radius: 15px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid #EAF0F6; }
  @media (min-width: 640px) {
    .panel-content {
      padding: 24px; } }

.lead-count {
  white-space: nowrap; }
  @media (min-width: 640px) {
    .lead-count {
      display: flex;
      align-items: center; } }
  .lead-count .count-total {
    font-size: 20px;
    line-height: 1;
    width: 36px;
    margin-right: 16px;
    text-align: right; }
  .lead-count .count-detail, .lead-count i {
    color: #319500;
    display: inline-block;
    vertical-align: middle; }
  .lead-count .count-detail {
    font-size: 12px;
    text-align: right;
    width: 22px;
    margin-right: 4px; }
  .lead-count i {
    font-size: 12px; }

.lead-price {
  color: #E77E04;
  font-size: 20px;
  line-height: 1; }

.clients {
  margin: 30px 0 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  @media (min-width: 960px) {
    .clients {
      margin: 48px 0 80px 40px; } }
  .clients .client-logo {
    margin: 10px; }
    @media (min-width: 960px) {
      .clients .client-logo {
        margin: 0 20px; } }

@media (min-width: 960px) {
  .request-form {
    padding-left: 96px;
    padding-right: 96px; } }

.icon-triangle, .icon-square {
  position: absolute;
  opacity: .1;
  color: #377dff;
  display: none; }
  @media (min-width: 1280px) {
    .icon-triangle, .icon-square {
      display: block; } }

.icon-triangle {
  font-size: 20px; }
  .icon-triangle-1 {
    left: -5%;
    transform: rotate(270deg);
    top: 15%; }
  .icon-triangle-2 {
    right: 35%;
    bottom: 20px; }
  .icon-triangle-3 {
    left: 35%;
    top: 55%; }
  .icon-triangle-4 {
    right: 4%;
    bottom: 20%; }
  .icon-triangle-5 {
    right: 20%;
    transform: rotate(270deg);
    top: 5%; }
  .icon-triangle-6 {
    right: 10%;
    bottom: 10%;
    transform: rotate(90deg); }
  .icon-triangle-7 {
    left: 5%;
    top: 4%; }
  .icon-triangle-8 {
    right: 15%;
    top: 60%;
    transform: rotate(270deg); }
  .icon-triangle-9 {
    left: 10%;
    bottom: 4%; }
  .icon-triangle-10 {
    right: 10%;
    top: 25%;
    transform: rotate(180deg); }
  .icon-triangle-11 {
    left: 20%;
    bottom: 25%;
    transform: rotate(90deg); }

.icon-square {
  font-size: 19px; }
  .icon-square-1 {
    right: -5%;
    transform: rotate(270deg);
    top: 40%; }
  .icon-square-2 {
    left: 10%;
    top: 5%; }
  .icon-square-3 {
    right: 15%;
    top: 25%; }
  .icon-square-4 {
    left: 45%;
    top: 50%; }
  .icon-square-5 {
    left: 50%;
    top: 36%; }
  .icon-square-6 {
    right: 15%;
    top: 5%; }
  .icon-square-7 {
    left: 10%;
    top: 5%; }
  .icon-square-8 {
    right: 20%;
    bottom: 15%; }
