input[type="text"] {
    background-color: #fff;
    border-radius: $borderRadiusSmall;
    color: $darkblueColor;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border: 0;
    @include transition(box-shadow);    

    &::-webkit-input-placeholder {
        color: #ccc;
    }
    &::-moz-placeholder {
        color: #ccc;
    }
    &:-ms-input-placeholder {
        color: #ccc;
    }
    &:-moz-placeholder {
        color: #ccc;
    }

    &:focus {
        box-shadow: 2px 2px 15px rgba(0,0,0,.25);
        outline: 0;
    }
}

.form {
    @include boxShadowDark;
    border-radius: $borderRadius;
    background-color: $blueColor;
    padding: 32px;
    
    @media (min-width: $viewportM) {
        padding: 40px;
    }

    .form-legend {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 24px;
        color: #fff;
    }

    .form-row:not(:first-child) {
        margin-top: 16px;
    }

    .form-row-btn {
        margin-top: 24px;
    }

    &-disabled {
        position: relative;
        user-select: none;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            cursor: not-allowed;
            bottom: 0;
            z-index: 1;
        }
    }
}