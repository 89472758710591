.header, .footer {
    
    a {
        color: #fff;
        text-decoration: none;
    }
    
    .logo {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    
    .email {
        margin-left: 40px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.header {
    margin-bottom: 54px;
}

.footer {
    background-color: $darkblueColor;
    color: #fff;
    padding: 24px 0;
}