.container {
    max-width: 1184px;
    margin: 0 auto;
    padding: 0 16px;
    
    @media (min-width: $viewportM) {
        padding: 0 32px;
    }
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    
    @media (min-width: $viewportM) {
        margin-left: -32px;
    }

    > * {
        padding-left: 16px;
        width: 100%;

        @media (min-width: $viewportM) {
            padding-left: 32px;
        }
    }

    &-center {
        margin-left: auto;
        margin-right: auto;
    }
}

.w {
    @each $gridName, $gridWidth in $grid {
        &-#{$gridName} {
            width: $gridWidth;
        }
    }
}

@each $viewportName, $viewportWidth in $viewports {
    .w-#{$viewportName} {
        @media (min-width: $viewportWidth) {
            @each $gridName, $gridWidth in $grid {
                &-#{$gridName} {
                    width: $gridWidth;
                }
            }
        }
    }
    .grid-w-#{$viewportName} {
        @media (min-width: $viewportWidth) {
            @each $gridName, $gridWidth in $grid {
                &-#{$gridName} > * {
                    width: $gridWidth;
                }
            }
        }
    }
}

[class*=push-] {
    position: relative;
}

.push {
    &-m {
        @media (min-width: $viewportM) {
            &-1-12 {
                left: 8.333333%;
            }
        }
    }
}