[class*="flex-"] {
    display: flex;
    flex-wrap: wrap;
}

.flex {

    &-x {
        &-between {
            justify-content: space-between
        }
    }
    
    &-y {
        &-center {
            align-items: center;
        }
    }

    &-nowrap {
        flex-wrap: nowrap;
    }
}