.hero {
    background: $blueColor url(../images/pattern.png);
    color: #fff;
    padding: 40px 0 60px;

    @media (min-width: $viewportM) {
        padding-bottom: 80px;
    }

    &-title {
        font-size: 22px;
        line-height: 32px;
        font-weight: bold;
        
        @media (min-width: $viewportM) {
            line-height: 40px;
            font-size: 32px;
        }
    }

    &-text {
        margin: 24px 0;
        font-size: 18px;
        line-height: 32px;

        @media (min-width: $viewportM) {
            font-size: 24px;
        }
    }

    &-img {
        background: #fff;
        padding: 8px;
        border-radius: $borderRadius;
        box-shadow: 5px 5px 25px rgba(0,0,0,.2);
        margin-top: 20px;

        img {
            display: block;
            margin: 0 auto;
        }

        @media (min-width: $viewportM) {
            margin-top: 0;
        }
    }
}