.btn {
    background-color: $pinkColor;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    text-decoration: none;
    padding: 0 32px;
    border-radius: $borderRadiusSmall;
    @include transition(background-color);

    &:hover {
        background-color: #e0398e;
    }

    &:focus {
        outline: 0;
    }
}