@font-face {
    font-family: 'Avenir Next', sans-serif;
    src:
        local('Avenir Next'),
        local('AvenirNext-Regular'),
        url('AvenirNext-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next', sans-serif;
    src:
        local('Avenir Next'),
        local('AvenirNext-Bold'),
        url('AvenirNext-Bold.woff') format('woff');
    font-weight: bold;
}

body {
    font-family: 'Avenir Next', sans-serif;
    line-height: 24px;
    font-size: 15px;
    color: $darkblueColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    @media (min-width: $viewportS) {
        font-size: 16px;
    }        
}

a {
    color: $blueColor;
}

p {
    margin: 0 0 16px;
}

* + ul, * + p {
    margin-top: 16px;
}


ul {
    list-style-type: square;
    padding-left: 20px;

    li:not(:first-child) {
        margin-top: 16px;
    }
}

.text {
    &-right {
        text-align: right;
        @media (min-width: $viewportS) {
            &-s {
                text-align: right;
            }
        }
    }
    &-center {
        text-align: center;

        @media (min-width: $viewportS) {
            &-s {
                text-align: center;
            }
        }
    }
}