$blueColor: #377dff;
$darkblueColor: #27346e;
$pinkColor: #fd4da6;
$blueLightColor: #f6f9fc;

$borderColor: #EAF0F6;

$borderRadius: 15px;
$borderRadiusSmall: 5px;

$viewportS: 640px;
$viewportM: 960px;
$viewportL: 1280px;

$viewports: (
    s: $viewportS,
    m: $viewportM,
    l: $viewportL,
);

$grid: (
    '1-12' : 8.33333333%,
    '1-6' : 16.666666%,
    '1-4' : 25%,
    '1-3' : 33.333333%,
    '1-2' : 50%,
    '2-3' : 66.666666%,
    '3-4' : 75%,
    '5-6' : 83.333333%,
    '5-12' : 41.666666%,
    '7-12' : 58.333333%,
    '1-1' : 100%
);

// icomoon

$icomoon-font-path: "../fonts/icomoon" !default;

$icon-square: \e900;
$icon-triangle: \e901;
$icon-cart: \e902;
$icon-billboard: \e903;
$icon-business: \e904;
$icon-calculator: \e905;
$icon-control: \e906;
$icon-eye: \e907;
$icon-hand_mobile: \e908;
$icon-idea: \e909;
$icon-monitor: \e90a;
$icon-phone: \e90b;
$icon-rocket: \e90c;