@import "variables";
@import "mixins";
@import "normalize-sass/normalize";
@import "base/btn";
@import "base/core";
@import "base/flex";
@import "base/forms";
@import "base/grid";
@import "base/table";
@import "base/tabs";
@import "base/type";

// icomoon
@import "base/icomoon";

// sections
@import "sections/header";
@import "sections/hero";
@import "sections/questions";
// @import "sections/hero";

section {
    position: relative;

    .grid {
        align-items: center;
    }

    table {
        table-layout: fixed;
    }
}

.section {
    &-block {
        padding: 60px 0;
        position: relative;
        overflow: hidden;
        
        @media (min-width: $viewportM) {
            padding: 104px 0;
        }
    }

    .container {
        position: relative;
    }

    &-icon {
        margin-bottom: 24px;
        display: block;
        font-size: 40px;
    }

    &-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
        
        @media (min-width: $viewportS) {
            line-height: 32px;
            font-size: 26px;
        }
    }

    &-text {
        margin-bottom: 24px;
    }
}

.panel {
    &-blue, &-darkblue {
        position: relative;
        
        &:before {
            left: 0;
            top: 0;
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: $borderRadius;
            box-shadow: 4px 4px 25px rgba(0,0,0,.2);
        }
    }

    &-blue:before {
        background-color: $blueColor;
    }

    &-darkblue {
        .panel-content {
            padding: 0;
            border: 0;
            background-color: $darkblueColor;
        }

        img {
            border-radius: $borderRadius;
            display: block;
            opacity: 0.75;
        }

        &:before {
            background-color: $darkblueColor;
        }
    }

    &-left-top:before {
        transform: translateX(-10px) translateY(-10px);

        @media (min-width: $viewportS) {
            transform: translateX(-20px) translateY(-20px);
        }
    }

    &-right-bottom:before {
        transform: translateX(10px) translateY(10px);

        @media (min-width: $viewportS) {
            transform: translateX(20px) translateY(20px);
        }
    }

    &-content {
        position: relative;
        background-color: #fff;
        padding: 16px;
        border-radius: $borderRadius;
        box-shadow: 4px 4px 20px rgba(0,0,0,.08);
        border: 1px solid $borderColor;

        @media (min-width: $viewportS) {
            padding: 24px;
        }
    }

}

.lead-count {
    white-space: nowrap;
    
    @media (min-width: $viewportS) {
        display: flex;
        align-items: center;
    }

    .count-total {
        font-size: 20px;
        line-height: 1;
        width: 36px;
        margin-right: 16px;
        text-align: right;
    }

    .count-detail, i {
        color: #319500;
        display: inline-block;
        vertical-align: middle;
    }

    .count-detail {
        font-size: 12px;
        text-align: right;
        width: 22px;
        margin-right: 4px;
    }

    i {
        font-size: 12px;
    }
}

.lead-price {
    color: #E77E04;
    font-size: 20px;
    line-height: 1;
}

.clients {
    margin: 30px 0 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: $viewportM) {
        margin: 48px 0 80px 40px;
    }

    .client-logo {
        margin: 10px;
        
        @media (min-width: $viewportM) {
            margin: 0 20px;

        }
    }

    
}

.request-form {
    
    @media (min-width: $viewportM) {
        padding-left: 96px;
        padding-right: 96px;
    }
}

.icon-triangle, .icon-square {
    position: absolute;
    opacity: .1;
    color: $blueColor;
    display: none;

    @media (min-width: $viewportL) {
        display: block;
    }
}

.icon-triangle {
    font-size: 20px;

    &-1 {
        left: -5%;
        transform: rotate(270deg);
        top: 15%;
    }

    &-2 {
        right: 35%;
        bottom: 20px;
    }

    &-3 {
        left: 35%;
        top: 55%;
    }

    &-4 {
        right: 4%;
        bottom: 20%;
    }

    &-5 {
        right: 20%;
        transform: rotate(270deg);
        top: 5%;
    }

    &-6 {
        right: 10%;
        bottom: 10%;
        transform: rotate(90deg);
    }

    &-7 {
        left: 5%;
        top: 4%;
    }

    &-8 {
        right: 15%;
        top: 60%;
        transform: rotate(270deg);
    }

    &-9 {
        left: 10%;
        bottom: 4%;
    }

    &-10 {
        right: 10%;
        top: 25%;
        transform: rotate(180deg);
    }
    
    &-11 {
        left: 20%;
        bottom: 25%;
        transform: rotate(90deg);
    }
}

.icon-square {
    font-size: 19px;

    &-1 {
        right: -5%;
        transform: rotate(270deg);
        top: 40%;
    }

    &-2 {
        left: 10%;
        top: 5%;
    }

    &-3 {
        right: 15%;
        top: 25%;
    }

    &-4 {
        left: 45%;
        top: 50%;
    }

    &-5 {
        left: 50%;
        top: 36%;
    }

    &-6 {
        right: 15%;
        top: 5%;
    }

    &-7 {
        left: 10%;
        top: 5%;
    }

    &-8 {
        right: 20%;
        bottom: 15%;
    }
}

