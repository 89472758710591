table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 24px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    @media (min-width: $viewportS) {
        font-size: 14px;
    }        
}

th, td {
    height: 48px;
    padding: 8px;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    word-wrap: break-word;

    @media (min-width: $viewportS) {
        padding: 8px 16px;
    }

    tr &:first-child {
        border-left: 1px solid $borderColor;
    }
}

th {
    background-color: $blueLightColor;
    vertical-align: top;
    font-weight: normal;
    text-align: left;

    thead tr:first-child & {
        border-top: 1px solid $borderColor;
    }

    tr:first-child &:first-child {
        border-top-left-radius: $borderRadiusSmall;
    }

    tr:first-child &:last-child {
        border-top-right-radius: $borderRadiusSmall;
    }
}

td {
    vertical-align: middle; 

    tr:last-child &:first-child {
        border-bottom-left-radius: $borderRadiusSmall;
    }

    tr:last-child &:last-child {
        border-bottom-right-radius: $borderRadiusSmall;
    }   
}