* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

.bg-light {
    background: $blueLightColor;
}

.mt {
    &-24 {
        margin-top: 24px;
    }

    &-32 {
        margin-top: 32px;
    }

    &-104 {
        margin-top: 104px;

        @media (max-width: $viewportM) {
            margin-top: 50px;
        }
    }

    &-136 {
        margin-top: 136px;

        @media (max-width: $viewportM) {
            margin-top: 80px;
        }
    }

    &-s-16 {
        @media (max-width: $viewportM) {
            margin-top: 16px;
        }
    }

    &-s-32 {
        @media (max-width: $viewportM) {
            margin-top: 32px;
        }
    }
}

.hidden {
    &-s {
        @media (max-width: $viewportS) {
            display: none !important;
        }
    }

    &-m {
        @media (max-width: $viewportM) {
            display: none !important;
        }
    }
}

.order-m-1 {
    @media (max-width: $viewportM) {
        order: 1;
    }
}