.service {
    &:not(:first-child) {
        margin-top: 56px;
    }
}

.arrows {
    margin-right: -10px;
    display: none;

    @media (min-width: $viewportL) {
        display: block;
    }
}

.question {
    background-color: $blueLightColor;
    padding: 16px;
    text-align: center;
    color: #686F8E;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid $borderColor;
    border-radius: 12px;
    box-shadow: -2px 2px 6px rgba(0,0,0,.05);
    flex: 1;
    margin: 5px;
    min-width: 150px;

    
    @media (min-width: $viewportM) {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $viewportM) {
        }
    }
}